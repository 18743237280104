import React from "react";
import LoyolaLogo from "../assets/images/scriptLoyola.jpeg";
import Section from "./Section";

const Volleyball = () => {
  const headerComponent = (
    <div className={"loyola-logo-container"}>
      <a
        href={
          "https://loyolaramblers.com/sports/mens-volleyball/roster/ian-cowen/1302/"
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className={"loyola-logo"} src={LoyolaLogo} alt={"Loyola"} />
      </a>
    </div>
  );
  return (
    <Section
      backgroundClassName={"section-background volleyball-background"}
      headerComponent={headerComponent}
      component={
        <>
          <div className={"section-background volleyball-background"} />
          <div
            className={"one-col-section volleyball"}
            style={{ margin: "-100vh auto 0 auto" }}
          >
            <div>{"BS in Math & CS"}</div>
            <div>{"Class of 2020"}</div>
            <div>{"Setter for NCAA Volleyball Team"}</div>
            <div>{"Senior Captain"}</div>
          </div>
        </>
      }
    />
  );
};

export default Volleyball;
