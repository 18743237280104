import React from "react";
import LinkedIn from '../assets/images/linkedin.png';
import GitHub from '../assets/images/github.svg';
import Loyola from '../assets/images/loyola.png'
import StatsPerform from '../assets/images/statsperform-no-bg.png';
import RobinhoodFeather from '../assets/images/robinhood-feather.png';
import MusicGif from '../assets/images/Music.gif';

const Profiles = () => {
    return <div className={'profiles'}>
        <a href={"https://www.linkedin.com/in/ian-cowen/"} target="_blank" rel="noopener noreferrer">
            <img className={'profile-icon'} src={LinkedIn} alt={'LinkedIn'}/>
        </a>
        <a href={"https://github.com/icowen"} target="_blank" rel="noopener noreferrer">
            <img className={'profile-icon github'} src={GitHub} alt={'GitHub'}/>
        </a>
        <a href={"https://loyolaramblers.com/sports/mens-volleyball/roster/ian-cowen/1302"} target="_blank"
           rel="noopener noreferrer">
            <img className={'profile-icon'} src={Loyola} alt={'Loyola'}/>
        </a>
        <a href={"https://www.statsperform.com/"} target="_blank" rel="noopener noreferrer">
            <img className={'profile-icon'} src={StatsPerform} alt={'Stats Perform'}/>
        </a>
        <a href={"https://robinhood.com/us/en/"} target="_blank" rel="noopener noreferrer">
            <img className={'profile-icon'} src={RobinhoodFeather} alt={'Stats Perform'}/>
        </a>
        <a href={"https://music.iancowen.ninja"} target="_blank" rel="noopener noreferrer">
            <img className={'profile-icon'} src={MusicGif} alt={'Music'}/>
        </a>
    </div>
};

export default Profiles;