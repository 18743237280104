import React from "react";
import "./App.css";
import AboutMe from "./Components/AboutMe";
import Volleyball from "./Components/Volleyball";
import StatsPerform from "./Components/StatsPerform";
import Nim from "./Components/Nim";
import AdOnTheMove from "./Components/AdOnTheMove";
import Aws from "./Components/Aws";
import Pigs from "./Components/Pigs";
import Robinhood from "./Components/Robinhood";
import Music from "./Components/Music";
import Profiles from "./Components/Profiles";
import Stoak from "./Components/Stoak";

const App = () => {
  return (
    <div className="App">
      <Profiles />
      <div className={"sections-container"}>
        <div className={"sections"}>
          <AboutMe />
          <Robinhood />
          <Music />
          <Stoak />
          <StatsPerform />
          <Aws />
          <Volleyball />
          <Nim />
          <Pigs />
          <AdOnTheMove />
        </div>
      </div>
    </div>
  );
};

export default App;
