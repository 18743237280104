import React from "react";
import Section from "./Section";
import RobinhoodLogo from "../assets/images/robinhood-logo.jpeg";


const Robinhood = () => {
  const headerComponent = (
    <div className={"robinhood-logo-container"}>
      <a
        href={"https://robinhood.com/us/en/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className={"robinhood-logo"}
          src={RobinhoodLogo}
          alt={"Robinhood"}
        />
      </a>
    </div>
  );
  return <Section headerComponent={headerComponent}
  component={
      <div className={'one-col-section'}>
          <div>{'Democratizing Finance for All'}</div>
          <div>{'Data Engineer'}</div>
          <div>{'February 2022'}</div>
      </div>
  }/>;
};

export default Robinhood;
