import React from "react";
import Section from "./Section";

const Pigs = () => {
  const componentHeader = <div className={"pigs-title"}>{"Pass the Pigs"}</div>;
  return (
    <Section
      backgroundClassName={"section-background pigs-background"}
      headerComponent={componentHeader}
      component={
        <div className={"one-col-section"}>
          <div
            className={"pigs-content"}
          >
            <span className={"pigs-row"}>{"Online version of "}</span>
            <a
              href={"https://en.wikipedia.org/wiki/Pass_the_Pigs"}
              target="_blank"
              rel="noopener noreferrer"
            >
              {"Pass the Pigs."}
            </a>
            <div className={"pigs-row"}>
              {"Created with React and three.js. Hosted on S3."}
            </div>
            <div className={"pigs-row"}>
              <span>{"View the code "}</span>
              <a
                href={"https://github.com/icowen/pigs"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {"on GitHub."}
              </a>
            </div>
            <div className={"pigs-play-button"}>
              <a
                href={"http://pigs-website.s3-website-us-east-1.amazonaws.com/"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {"Play Now!"}
              </a>
            </div>
          </div>
        </div>
      }
    />
  );
};
export default Pigs;
