import React from "react";
import Section from "./Section";
import StoakLogo from "../assets/images/stoak-logo-dark.png";

const Stoak = () => {
  return (
    <Section
      headerComponent={
        <div className={"stoak-logo-container"}>
      <a
        href={"https://stoak.us/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className={"stoak-logo"}
          src={StoakLogo}
          alt={"StoakLogo"}
        />
      </a>
      </div>
      }
      component={
        <div
          className={"one-col-section"}
          style={{
            background:
              "radial-gradient(circle at 50% -50% , white 5%, transparent 50%), black",
            width: "100%",
            paddingTop: "4rem",
          }}
        >
          <div className={"scheduler-link"}>
            <a
              href={"https://stoak.us"}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "2rem",
              }}
            >
              <div>{"Create Volleyball Competitions"}</div>
              <div>{"Design single matches, tournaments, leagues, and more!"}</div>
              <div>{"Work in Progress"}</div>
              <div style={{ textDecoration: "underline", color: "white" }}>
                {"stoak.us"}
              </div>
            </a>
          </div>
        </div>
      }
    />
  );
};

export default Stoak;
