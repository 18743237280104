import React from "react";
import AotmLogo from "../assets/images/AOTM_LOGO.png";
import Slideshow from "./Slideshow";
import Ads from "../assets/images/AOTM_ads.png";
import Routes from "../assets/images/AOTM_routes.png";
import Variance from "../assets/images/AOTM_variance.png";
import Section from "./Section";

const AdOnTheMove = () => {
  const headerComponent = (
    <div className={"aotm-logo-container"}>
      <a
        href={"https://still-reef-22431.herokuapp.com/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={AotmLogo} alt={"Ad On The Move"} className={"aotm-logo"} />
      </a>
    </div>
  );
  return (
    <Section
      headerComponent={headerComponent}
      component={
        <div
          className={"one-col-section"}
          style={{ gridGap: "10px", marginTop: "20px" }}
        >
          <div>
            {"Created reports on truck activity, advertisement exposure, and " +
              "discrepancies between reporting agencies."}
          </div>
          <div className={"slideshow-container"}>
            <Slideshow imgs={[Ads, Routes, Variance]} />
          </div>
        </div>
      }
    />
  );
};

export default AdOnTheMove;
