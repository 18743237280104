import React, { useRef, useState, useEffect } from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";

const Section = ({ component, headerComponent, backgroundClassName }) => {
  const domRef = useRef();
  const [isVisible, setVisible] = useState(false);
  const { height } = useWindowDimensions();

  useEffect(() => {
    const cur = domRef.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });

    observer.observe(cur);

    return () => observer.unobserve(cur);
  }, []);

  return (
    <div
      ref={domRef}
      className={`section ${isVisible ? "is-visible" : ""}`}
      style={{ minHeight: `${height}px` }}
    >
      {headerComponent ? (
        <div className={"section-header"}>{headerComponent}</div>
      ) : (
        <></>
      )}
      <div
        className={`section-container ${
          backgroundClassName ? backgroundClassName : ""
        }`}
      >
        {component}
      </div>
    </div>
  );
};

export default Section;
