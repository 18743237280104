import React from "react";
import Scroll from "./Scroll";
import Section from "./Section";

const AboutMe = () => {
  return (
    <Section
      component={
        <div
          className={"one-col-section"}
          style={{ fontSize: "x-large", gridTemplateRows: "20% 80%" }}
        >
          <div>
            <div>{"Hi, I'm Ian."}</div>
            <div>{"Currently based in Chicago."}</div>
          </div>
          <div style={{position: "relative"}}>
            <Scroll />
          </div>
        </div>
      }
    />
  );
};

export default AboutMe;
