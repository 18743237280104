import React from "react";
import Section from "./Section";
import MusicGif from "../assets/images/Music.gif";

const color = "rgb(98, 209, 246)";
const Music = () => {
  return (
    <Section
      headerComponent={
        <div
          style={{
            fontSize: "48px",
            height: "100px",
            background: "black",
            borderBottom: `solid ${color} 2px`,
            lineHeight: "100px",
            color,
          }}
        >
          {"Tunes"}
        </div>
      }
      component={
        <div className={"one-col-section"}>
          <div className={"music-link"}>
            <a
              href={"https://music.iancowen.ninja"}
              target="_blank"
              rel="noopener noreferrer"
            >
              {"Take a peek at what I'm listening to"}
              <img
                src={MusicGif}
                alt={"music icon"}
                height={200}
                className={"music-equalizer"}
              />
            </a>
          </div>
        </div>
      }
    />
  );
};

export default Music;
