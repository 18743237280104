import React from "react";
import StatsLogo from "../assets/images/statsperform-full.png";
import Section from "./Section";

const StatsPerform = () => {
  const headerComponent = (
    <div className={"stats-perform-logo-container"}>
      <a
        href={"https://www.statsperform.com/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className={"stats-perform-logo"}
          src={StatsLogo}
          alt={"StatsPerform"}
        />
      </a>
    </div>
  );
  return (
    <Section
      headerComponent={headerComponent}
      component={
        <div className={"one-col-section"}>
          <div>
            {"June 2020 - February 2022"}
            <br />
            <br />
            {
              "Architected global AWS cloud data platform that centralizes and unifies data sources for teams at this AI driven sports data company"
            }
          </div>
        </div>
      }
    />
  );
};

export default StatsPerform;
