import React, {Component} from "react";


class Slideshow extends Component {
    state = {current: 0};

    componentDidMount() {
        window.setInterval(this.updateImg, 5000);
    }

    updateImg = () => {
        this.setState(prevState => {
            return {current: (prevState.current + 1) % this.props.imgs.length}
        });
    };

    render() {
        const {imgs} = this.props;
        let {current} = this.state;
        return <div className={'slideshow-container'}>
            <img className={'slideshow-img'} alt={current} src={imgs[current]}/>
        </div>

    }
}

export default Slideshow;