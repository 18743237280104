import React from "react";
import AwsCerts from "../assets/images/aws-certifications.png";
import AwsCap from "../assets/images/aws-cap.png";
import AwsCas from "../assets/images/aws-cas.png";
import AwsDa from "../assets/images/aws-da.png";
import AwsDb from "../assets/images/aws-db.png";
import AwsDev from "../assets/images/aws-dev.png";
import AwsSec from "../assets/images/aws-sec.png";
import Section from "./Section";

const Aws = () => {
  const styling = {
    maxHeight: "100px",
    width: "50vw",
    maxWidth: "352px",
  };
  const headerComponent = (
    <div className={"aws-title"}>
      <a
        href={"https://aws.amazon.com/certification/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={AwsCerts} className={"aws-certs-logo"} alt={"aws-logo"}/>
      </a>
    </div>
  );

  return (
    <Section
      headerComponent={headerComponent}
      component={
        <div
          className={"three-col-section"}
          style={{ gridGap: "50px", paddingTop: "20px" }}
        >
          <div className={"aws-cert-container"}>
            <div>{"September 2021"}</div>
            <img src={AwsCap} className={"aws-cert"} styling={styling} alt={"aws-cap"}/>
          </div>
          <div className={"aws-cert-container"}>
            <div>{"August 2021"}</div>
            <img src={AwsCas} className={"aws-cert"} styling={styling}alt={"aws-cas"} />
          </div>
          <div className={"aws-cert-container"}>
            <div>{"July 2021"}</div>
            <img src={AwsDb} className={"aws-cert"} styling={styling} alt={"aws-db"}/>
          </div>
          <div className={"aws-cert-container"}>
            <div>{"June 2021"}</div>
            <img src={AwsSec} className={"aws-cert"} styling={styling} alt={"aws-sec"}/>
          </div>
          <div className={"aws-cert-container"}>
            <div>{"May 2021"}</div>
            <img src={AwsDa} className={"aws-cert"} styling={styling}alt={"aws-da"} />
          </div>
          <div className={"aws-cert-container"}>
            <div>{"January 2021"}</div>
            <img src={AwsDev} className={"aws-cert"} styling={styling} alt={"aws-dev"}/>
          </div>
          <div />
        </div>
      }
    />
  );
};

export default Aws;
