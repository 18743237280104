import React from "react";
import NimGraph from "../assets/images/fancygraph.png";
import Section from "./Section";

const Nim = () => {
  const headerComponent = <div className={"nim-title"}>{"Nim"}</div>;
  return (
    <Section
      headerComponent={headerComponent}
      component={
        <div className={"two-col-section"}>
          <div className={"one-col-section"}>
            <div>
              {"Nim is a classic math game commonly studied in game theory."}
            </div>
            <div>
              {"Along with "}
              <a
                href={"http://webpages.math.luc.edu/~ptingley/"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {"Dr. Tingley"}
              </a>
              {
                "and Zen Nguyen, we created and studied a modified version of nim."
              }
            </div>

            <div>
              {
                "I wrote a Java program that categorizes 2 million states of the game as either "
              }
              <i>winning</i>
              {" or "}
              <i>losing</i>
              {". A section of the results are shown on the right."}
            </div>
            <div>
              {
                "Now, we are in the process of writing a paper that explains some interesting behavior of this particular game and the states of the game."
              }
            </div>
          </div>
          <div className={"nim-wrapper"}>
            <img className={"nim-image"} src={NimGraph} alt={"nim graph"}/>
          </div>
          <div />
        </div>
      }
    />
  );
};

export default Nim;
